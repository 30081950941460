$(document).ready(initPage);

function initPage() {
  $('.footer-up').click(function () {
    $('html, body').animate({ scrollTop: 0 }, 1000);
  });

  $('.header-nav-toggler').click(function () {
    $('.header-nav').toggleClass('active');
    $('.header-nav-toggler').toggleClass('active');
  });
}
